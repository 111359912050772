import { createAction, handleActions } from 'redux-actions';
import { path, filter } from 'lodash/fp';
import { nanoid } from 'nanoid';
const defaultState = { notifications: [] };

// actions
export class NotificationAction {
  // export type Notification = {
  //   id?: string,
  //   title: string,
  //   body?: any,
  //   autohide?: boolean,
  //   delay?: number,
  // };
  static show = (notification) => (dispatch) => {
    const payload = {
      id: nanoid(),
      autohide: true,
      delay: 3000,
      ...notification,
    };

    dispatch({
      type: 'SHOW_NOTIFICATION',
      payload,
    });
  };
  static dismiss = createAction('DISMISS_NOTIFICATION');
}

// selectors
export class NotificationSelector {
  static getNotifications = path('notification.notifications');
}

// handlers
function handleShow(state, { payload }) {
  const notifications = [payload, ...state.notifications];
  return { ...state, notifications };
}

function handleDismiss(state, { payload }) {
  const notifications = filter((n) => n.id != payload, state.notifications);
  return { ...state, notifications };
}

// reducer
const reducer = handleActions(
  {
    SHOW_NOTIFICATION: handleShow,
    [NotificationAction.dismiss]: handleDismiss,
  },
  defaultState
);

export default reducer;
